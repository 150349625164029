import { ReactElement } from "react";
import { useRouter } from "next/router";
import CardWrapper from "@/components/CardWrapper/CardWrapper";
import Card from "@/components/Card/Card";
import CardUl from "@/components/WidgetRenderer/WidgetRenderer.style";
import {
  joinWordsWithHyphen,
  removePagesQueryAndLocaleFromPath,
} from "@/lib/string";
import { ContentForCard, ContentSummary } from "@/types/api/content";
import { ViewAllLink } from "@/components/ViewAllLink/ViewAllLink";
import { routeToArray } from "@/lib/array";
import { isActiveCompetition } from "@/lib/competitions";
import { NEWS_RELATED_PATHS } from "@/constants/content";
import { getViewAllContentPath } from "@/lib/content";

export interface LatestContentProps {
  content?: ContentForCard[] | ContentSummary[] | null;
  listHeader: string;
  hideHeader?: boolean;
  authorName?: string;
  region?: string | null;
}

export const LatestContent = ({
  content,
  listHeader,
  hideHeader = false,
  authorName,
  region,
}: LatestContentProps): ReactElement | null => {
  const { asPath } = useRouter();
  const hasContent = content && content.length > 0;
  const cardContentFullPath = hasContent ? content[0].full_url_path : undefined;

  const pathWithoutLocale = removePagesQueryAndLocaleFromPath(asPath);
  const pathWithoutLocaleArray = routeToArray(pathWithoutLocale);

  const isNewsPage = NEWS_RELATED_PATHS.includes(pathWithoutLocale);

  const isCompetitionPage =
    isActiveCompetition(pathWithoutLocaleArray[1]) &&
    pathWithoutLocaleArray.length === 2;

  // We only want to render the view all link for non-news/competition pages
  // So /news and /news/[competition] should not render the view all link
  // But any other page or /news/[slug] should
  const isNewsOrCompetitionPage = isNewsPage || isCompetitionPage;

  return hasContent ? (
    <CardWrapper
      listHeader={listHeader}
      hideHeader={hideHeader}
      headerChildren={
        !isNewsOrCompetitionPage && (
          <ViewAllLink
            allContentPath={getViewAllContentPath({
              region,
              contentPath: cardContentFullPath ?? "",
            })}
            region={region}
          />
        )
      }
    >
      <CardUl
        aria-labelledby={joinWordsWithHyphen(`card list ${listHeader}`)}
        columnGap
      >
        {content.map((article, i) => (
          <Card
            key={article._id}
            content={article}
            size={i === 0 ? "lg" : "sm"}
            displayExtract={i === 0}
            authorName={authorName}
          />
        ))}
      </CardUl>
    </CardWrapper>
  ) : null;
};

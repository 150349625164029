import {
  activeFaCompetitions,
  faCompetitionNameMap,
  fpCompetitionSlugToNameMap,
} from "@/constants/competitions";
import { Competition } from "@/types/competitions";
import { FA_BRAND, FP_BRAND } from "@/constants/brands";
import { getActiveBrand } from "@/lib/brand";

export function isActiveCompetition(
  competition: string
): competition is Competition {
  return activeFaCompetitions.includes(competition as Competition);
}

export function supportedCompetitionSlugFromName(
  name?: string
): Competition | null {
  if (!name) return null;

  const result: Competition[] = [];
  faCompetitionNameMap.forEach((value, key) => {
    if (value === name) {
      result.push(key as Competition);
    }
  });

  return result[0] || null;
}

export function getSupportedCompetitionSlugForBrandFromName(
  competitionName: string
): string | undefined {
  const brand = getActiveBrand();

  // On FA, we don't want to return links to FP competitions
  if (brand === FA_BRAND) {
    return supportedCompetitionSlugFromName(competitionName) ?? undefined;
  } else if (brand === FP_BRAND) {
    // Reverse the map, we want to access it using the competition name to get the slugs
    const fpCompetitionNameToSlugMap = new Map(
      Array.from(fpCompetitionSlugToNameMap, ([key, value]) => [value, key])
    );

    // On FP, we don't want to return links to FA competitions
    return fpCompetitionNameToSlugMap.get(competitionName);
  }

  return undefined;
}

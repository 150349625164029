import { ReactElement } from "react";
import Image from "next/image";
import { joinWordsWithHyphen } from "@/lib/string";
import styles from "@/components/CardWrapper/CardWrapper.module.scss";

interface Props {
  children: React.ReactNode;
  hideHeader?: boolean;
  competitionHeader?: boolean;
  listHeader: string;
  listImage?: string;
  headerChildren?: React.ReactNode;
  icon?: ReactElement | null;
  listSubheader?: string;
  date?: string;
}

function CardWrapper({
  children,
  hideHeader = false,
  competitionHeader = false,
  listHeader,
  listSubheader,
  listImage,
  headerChildren,
  icon,
  date,
}: Props) {
  const id = joinWordsWithHyphen(`card list ${listHeader}`);

  const cardListHeaderClassNamesObject = {
    [styles.cardListHeader]: true,
    [styles["cardListHeader--imageMarginRight"]]: !!listImage,
    // By default, this is set to false and will render all headers in uppercase
    [styles["cardListHeader--competitionHeader"]]: competitionHeader,
  };

  const cardListHeaderClassNamesObjectString = Object.keys(
    cardListHeaderClassNamesObject
  )
    .filter((className) => cardListHeaderClassNamesObject[className])
    .join(" ");

  return (
    <div className={styles.cardListContainer} data-testid="cardWrapper">
      {!hideHeader ? (
        <div
          className={`${styles.cardListHeaderContainer} ${
            competitionHeader
              ? styles["cardListHeaderContainer--competitionHeader"]
              : ""
          }`}
          id={id}
        >
          <div className={styles.titleContainer}>
            {icon ? <div className={styles.iconContainer}>{icon}</div> : null}
            <h2 className={cardListHeaderClassNamesObjectString}>
              {listImage ? (
                <Image
                  src={listImage}
                  width={23}
                  height={23}
                  alt={"Header logo"}
                />
              ) : (
                ""
              )}
              {listHeader}
            </h2>
          </div>
          {headerChildren ? (
            <div className={styles.headerChildrenContainer}>
              {headerChildren}
            </div>
          ) : null}
          {listSubheader ? (
            <>
              <div className={styles.flexBreak} />
              <h3 className={styles.subheader}>
                {listSubheader}{" "}
                {date ? <span className={styles.date}>{date}</span> : null}
              </h3>
            </>
          ) : null}
        </div>
      ) : null}
      {children}
    </div>
  );
}

export default CardWrapper;

import Image from "next/image";
import {
  unixTimestampMsToYearMonthDateHourMinAndSeconds,
  unixTimestampMsFromNow,
} from "@/lib/date";
import { mediaUrlFromSlug } from "@/lib/media";
import { ContentForCardWithOptionalUpdatedAt } from "@/types/api/content";
import {
  extractCategoryStringFromFullUrlPath,
  extractCategoryUrlFromFullUrlPath,
  extractToDisplay,
  formattedCategory,
} from "@/lib/string";
import { isLocalisedContentRoute } from "@/lib/content";
import { LinkNoPrefetch } from "../LinkNoPrefetch/LinkNoPrefetch";
import styled from "./Card.module.scss";

export type CardSize = "sm" | "md" | "lg";

interface Props {
  content: ContentForCardWithOptionalUpdatedAt;
  displayExtract?: boolean;
  size?: CardSize;
  authorName?: string;
}

const imageSizeMap = {
  sm: {
    width: 80,
    height: 104,
  },
  md: {
    width: 216,
    height: 163,
  },
  lg: {
    width: 378,
    height: 209,
  },
};

function Card({
  content,
  displayExtract,
  size = "md",
  authorName,
}: Props): JSX.Element {
  const { full_url_path = "" } = content;
  // we need to check if we are viewing a page that has localised content
  const isLocalisedContent = isLocalisedContentRoute(full_url_path);
  // if we are viewing localised content then we need to make sure
  // we're checking if the second path segment matches the category
  const topLevelPageIndex = isLocalisedContent ? 1 : 0;
  const category = extractCategoryStringFromFullUrlPath(full_url_path);
  const isCategoryTopLevelPage =
    category === full_url_path.split("/")[topLevelPageIndex];
  const categoryPageUrl = extractCategoryUrlFromFullUrlPath(full_url_path);

  const extract = displayExtract ? extractToDisplay(content) : null;

  const timeStamp = content.updated_at || "";
  const timeString = () => {
    if (!timeStamp) return "";

    return unixTimestampMsFromNow(timeStamp);
  };

  // If there are cards without a featured image in the LatestContent widget, we want them to display as the same size
  // as though they had images (to match the other cards)
  const cardHeightWithoutFeaturedImage = !content.meta.featured_image
    ? imageSizeMap[size].height
    : "unset";

  const checkSize = (size: CardSize, sizes: CardSize[]): boolean => {
    return sizes.includes(size);
  };

  return (
    <li
      className={
        styled[
          `cardContainer${checkSize(size, ["sm"]) ? `--${size}` : ""}`.trim()
        ]
      }
      data-testid={`card-${content._id}`}
    >
      <LinkNoPrefetch
        href={`/${full_url_path}`}
        className={
          styled[
            `cardLink${checkSize(size, ["md", "lg"]) ? `--${size}` : ""}`.trim()
          ]
        }
        data-testid={`cardLink`}
        style={{
          height: cardHeightWithoutFeaturedImage,
        }}
      >
        {content.meta.featured_image && (
          <div className={styled[`cardImageParent--${size}`]}>
            <Image
              src={mediaUrlFromSlug(content.meta.featured_image)}
              alt=""
              data-testid={content.meta.featured_image}
              className={
                styled[
                  `cardImage${
                    checkSize(size, ["lg"]) ? `--${size}` : ""
                  }`.trim()
                ]
              }
              fill
            />
          </div>
        )}
        <div
          className={
            styled[
              `cardBody${checkSize(size, ["lg"]) ? `--${size}` : ""}`.trim()
            ]
          }
          data-testid={`body-${content._id}`}
        >
          <div>
            <h3
              className={
                styled[
                  `cardHeader${
                    checkSize(size, ["sm"]) ? `--${size}` : ""
                  }`.trim()
                ]
              }
            >
              {content.title}
            </h3>
            {extract && (
              <p
                className={
                  styled[
                    `cardExtract${
                      checkSize(size, ["md", "lg"]) ? `--${size}` : ""
                    }`.trim()
                  ]
                }
                data-testid="extract"
              >
                {extract}
              </p>
            )}
          </div>
        </div>
      </LinkNoPrefetch>
      <div
        className={
          styled[`cardMeta${checkSize(size, ["lg"]) ? `--${size}` : ""}`.trim()]
        }
      >
        {authorName || isCategoryTopLevelPage ? (
          <span data-testid="category" className={styled.cardCategory}>
            {formattedCategory(authorName, category)}
          </span>
        ) : (
          <LinkNoPrefetch
            href={categoryPageUrl}
            className={styled["cardCategory"]}
            data-testid="category"
          >
            {formattedCategory(authorName, category)}
          </LinkNoPrefetch>
        )}
        {timeStamp ? (
          <span>
            <time
              dateTime={unixTimestampMsToYearMonthDateHourMinAndSeconds(
                timeStamp
              )}
              suppressHydrationWarning
            >
              {timeString()}
            </time>
          </span>
        ) : null}
      </div>
    </li>
  );
}

export default Card;
